<template>
  <div>
    <section class="mb-4 pt-3">
      <div class="container">
        <div class="bg-white shadow-sm rounded p-3">
          <div class="row">
            <div class="col-xl-5 col-lg-6 mb-4">
                <div class="sticky-top z-3 row gutters-10">
                  
                  <div class="col order-1 order-md-2">
                    <div class="aiz-carousel product-gallery" data-nav-for='.product-gallery-thumb' data-fade='true'>
                      <!-- <Swipe ref="banner" :autoplay="3000" :touchable="false" :show-indicator="false" class="home-banner">
                        <SwipeItem  v-for="(item, i) in bannerList" :key="i"> -->
                          <div class="carousel-box img-zoom rounded" >
                            <img class="img-fluid lazyload"  :src="bannerUrl" key="">
                          </div>
                        <!-- </SwipeItem>
                      </Swipe> -->
                    </div>
                  </div>
                  <div class="col-12 col-md-auto w-md-90px order-2 order-md-1 mt-3 mt-md-0">
                    <div class="aiz-carousel slick-initialized product-gallery-thumb slick-vertical relative" data-vertical="true">
                      <button type="button" class="slick-prev slick-arrow slick-disabled" @click="bannerPrev">
                        <i class="las la-angle-left"></i>
                      </button>
                      <Swipe ref="banner" @change="bannerChange" class="product-banner"  :touchable="touchable" :show-indicators="false" :vertical="true" :width="70" :height="75" :loop="false" >
                        <SwipeItem  v-for="(item, i) in bannerList" :key="i" :class="bannerIndex == i ? 'slick-current' : ''" @click="changeBanner(i)">
                          <div class="carousel-box c-pointer border p-1 rounded" >
                            <img class="lazyload mw-100 size-60px mx-auto"  :src="`${item}`" >
                          </div>
                        </SwipeItem>
                      </Swipe>
                      <button type="button" class="slick-next slick-arrow" @click="bannerNext">
                        <i class="las la-angle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
            </div>

            <div class="col-xl-7 col-lg-6">
              <div class="text-left">
                <h1 class="mb-2 fs-20 fw-600">
                  {{productInfo.name }}
                </h1>

                <div class="row align-items-center">
                  <div class="col-12">
                    <span class="rating">
                      <i class="las la-star active" v-for="m in productInfo.star" :key="m"></i>
                      <i class="las la-star" v-for="n in 5" :key="n" v-if="n > productInfo.star"></i>
                    </span>
                    <span class="ml-1 opacity-50">(0{{ $t('ping-lun') }})</span>
                  </div>
                  <div class="col-auto ml">
                    <small class="mr-2 opacity-50">{{ $t('gu-ji-yun-shu-shi-jian-3-tian') }}</small>
                  </div>
                </div>

                <hr>

                <div class="row align-items-center">
                  <div class="col-auto">
                    <small class="mr-2 opacity-50">{{ $t('suo-shu') }} </small><br>
                    <a @click="toCategoryDetail(detailData.CommodityClassification)" class="text-reset">{{detailData.CommodityClassification.Name}}</a>
                    <!-- <span>内部产品</span> -->
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-sm btn-soft-primary"  @click="goToInput" >{{ $t('Message-to-seller') }}</button>
                  </div>

                  <a  class="col-auto" @click="toKefu" v-show="false">
                    <button class="btn btn-sm btn-soft-primary" >{{ $t('zai-xian-ke-fu') }}</button>
                  </a>

<!--                  <div class="col-auto">-->
<!--                    <a>-->
<!--                      <img :src="detailData.CommodityClassification.Img || categoryUrl"  height="30">-->
<!--                    </a>-->
<!--                  </div>-->
                </div>

                <hr>

                <table class="table mb-0" v-if="tableList.length > 0">
                  <thead>
                    <tr>
                      <th>{{ $t('zui-xiao-shu-liang') }}</th>
                      <th>{{ $t('zui-da-shu-liang') }}</th>
                      <th>{{ $t('dan-jia') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in tableList" :key="i">
                      <td>{{ item.min }}</td>
                      <td>{{ item.max }}</td>
                      <td>{{ item.price }}</td>
                    </tr>
                  </tbody>
                </table>

                <!-- <div class="row no-gutters mt-3">
                  <div class="col-sm-2">
                    <div class="opacity-50 my-2">价格:</div>
                  </div>
                  <div class="col-sm-10 opacity-70">
                    <div class="fs-20 ">
                      <del>
                        $105.99
                        <span>/Pc</span>
                      </del>
                    </div>
                  </div>
                </div> -->

                <div class="row no-gutters my-2">
                  <div class="col-sm-2">
                    <div class="opacity-50">{{ $t('jia-ge-0') }}</div>
                  </div>
                  <div class="col-sm-10">
                    <div class="">
                      <strong class="h2 fw-600 text-primary">
                        <!-- {{resetPrice(detailData.Price)}} -->
                        {{getGoodsPrice()}}
                      </strong>
                      <!-- <span key="" class="opacity-70">/Pc</span> -->
                    </div>
                  </div>
                </div>

                <!-- 
                <div class="row no-gutters mt-4">
                  <div class="col-sm-2">
                    <div class="opacity-50 my-2">俱乐部积分:</div>
                  </div>
                  <div class="col-sm-10">
                    <div class="d-inline-block rounded px-2 bg-soft-primary border-soft-primary border">
                      <span class="strong-700">1119</span>
                    </div>
                  </div>
                </div> -->

                <hr>

                <form id="option-choice-form">
                  <div v-for="(option, i) in optionList" :key="i">
                    <div class="row no-gutters" >
                      <div class="col-sm-2">
                        <div class="opacity-50 my-2">
                          {{ option.skuPropertyName }}:
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="aiz-radio-inline">
                          <label class="aiz-megabox pl-0 mr-2" v-for="(label, index) in option.skuPropertyValues" :key="`label${index}`">
                            <input type="radio"  :name="option.name" :checked="option.optionIndex == index" @click="changeSize(i, index)">
                            <span  class="aiz-megabox-elem rounded d-flex align-items-center justify-content-center py-2 px-3">
                                {{ label.propertyValueDisplayName }}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <hr>
                  </div>
<!-- 
                  <div class="row no-gutters" >
                    <div class="col-sm-2">
                        <div class="opacity-50 my-2">颜色:</div>
                    </div>
                    <div class="col-sm-10">
                      <div class="aiz-radio-inline">
                        <label class="aiz-megabox pl-0 mr-2" v-for="(color, i) in colorList" :key="i">
                          <input type="radio" name="color">
                          <span class="aiz-megabox-elem rounded d-flex align-items-center justify-content-center p-1 mb-2">
                            <span class="size-30px d-inline-block rounded" :style="`background: ${color}`"></span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <hr> -->

                  <div class="row no-gutters">
                    <div class="col-sm-2">
                      <div class="opacity-50 my-2">{{ $t('shu-liang') }}</div>
                    </div>
                    <div class="col-sm-10">
                      <div class="product-quantity d-flex align-items-center">
                        <div class="row no-gutters align-items-center aiz-plus-minus mr-3"  style="width: 130px;">
                          <button class="btn col-auto btn-icon btn-sm btn-circle btn-light" type="button"   @click="minusCount">
                            <i class="las la-minus"></i>
                          </button>
                          <input type="number" class="col border-0 text-center flex-grow-1 fs-16 input-number" v-model="detailData.count" >
                          <button class="btn  col-auto btn-icon btn-sm btn-circle btn-light" type="button" @click="plusCount">
                            <i class="las la-plus"></i>
                          </button>
                        </div>
                        <div class="avialable-amount opacity-60">
                          (<span id="available-quantity">{{detailData.Stock}}{{ $t('ke-yong-de') }}</span>)
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="row no-gutters pb-3 " id="chosen_price_div">
                    <div class="col-sm-2">
                      <div class="opacity-50 my-2">{{ $t('zong-jia') }}</div>
                    </div>
                    <div class="col-sm-10">
                      <div class="product-price">
                        <strong id="chosen_price" class="h4 fw-600 text-primary">
                          {{getGoodsPrice(true)}}
                        </strong>
                      </div>
                    </div>
                  </div>

                </form>

                <div class="mt-3">
                  <!-- <a type="button" class="btn btn-primary buy-now fw-600">
                    <i class="la la-share"></i> 外部链接
                  </a> -->
                  <button type="button" class="btn btn-soft-primary mr-2 add-to-cart fw-600" @click="addCartList(1)">
                    <i class="las la-shopping-bag"></i>
                    <span class="d-none d-md-inline-block">{{ $t('tian-jia-dao-gou-wu-che') }}</span>
                  </button>
                  <button type="button" class="btn btn-primary buy-now fw-600" @click="toBuy" v-if="detailData.Stock > 0">
                    <i class="la la-shopping-cart"></i>{{ $t('li-ji-gou-mai') }}
                  </button>
                  <button type="button" class="btn btn-secondary out-of-stock fw-600 " disabled v-if="detailData.Stock == 0">
                    <i class="la la-cart-arrow-down"></i> {{ $t('que-huo') }}
                  </button>
                </div>



                <div class="d-table width-100 mt-3">
                  <div class="d-table-cell">
                    <button type="button" class="btn pl-0 btn-link fw-600" @click="addCartList(2)">
                      {{ $t('tian-jia-dao-yuan-wang-qing-dan') }} </button>
                    <!-- <button type="button" class="btn btn-link btn-icon-left fw-600" >
                      加入对比
                    </button> -->
                    <!-- <div>
                      <button type=button id="ref-cpurl-btn" class="btn btn-sm btn-secondary">复制推广链接</button>
                    </div> -->
                  </div>
                </div>


                <div class="row no-gutters mt-3">
                  <div class="col-2">
                    <div class="opacity-50 mt-2">{{ $t('tui-kuan') }}</div>
                  </div>
                  <div class="col-10">
                    <a  target="_blank">
                      <img :src="ruleUrl" height="36">
                    </a>
                    <a href="javascript:void(0)" @click="toPath('returnPolicy')" class="ml-2 red danger" >{{ $t('cha-kan-zheng-ce') }}</a>
                  </div>
                </div>

                <div class="row no-gutters mt-4">
                  <div class="col-sm-2">
                      <div class="opacity-50 my-2">{{ $t('fen-xiang') }}</div>
                  </div>
                  <div class="col-sm-10">
                    <div class="aiz-share">
                      <div class="jssocials-shares">
                        <div class="jssocials-share jssocials-share-email">
                          <a target="_self" :href="`mailto:?&amp;body=${shareLink}`" class="jssocials-share-link">
                            <i class="lar la-envelope jssocials-share-logo"></i>
                          </a>
                        </div>
                        <div class="jssocials-share jssocials-share-twitter">
                          <a target="_blank" :href="`https://twitter.com/share?url=${shareLink}`" class="jssocials-share-link">
                            <i class="lab la-twitter jssocials-share-logo"></i>
                          </a>
                        </div>
                        <div class="jssocials-share jssocials-share-facebook">
                          <a target="_blank" :href="`https://facebook.com/sharer/sharer.php?u=${shareLink}`" class="jssocials-share-link">
                            <i class="lab la-facebook-f jssocials-share-logo"></i>
                          </a>
                        </div>
                        <div class="jssocials-share jssocials-share-linkedin">
                          <a target="_blank" :href="`https://www.linkedin.com/shareArticle?mini=true&amp;url=${shareLink}`" class="jssocials-share-link">
                            <i class="lab la-linkedin-in jssocials-share-logo"></i>
                          </a>
                        </div>
                        <div class="jssocials-share jssocials-share-whatsapp">
                          <a target="_self" :href="`whatsapp://send?text=${shareLink}`" class="jssocials-share-link">
                            <i class="lab la-whatsapp jssocials-share-logo"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mb-4">
      <div class="container">
        <div class="row gutters-10">
          <div class="col-xl-3 order-1 order-xl-0" >
            <div class="bg-white shadow-sm mb-3">
              <div class="position-relative p-3 text-left">
                <div class="absolute-top-right p-2 bg-white z-1">
                  <img :src="starUrl" alt="">
                </div>
                <div class="opacity-50 fs-12 border-bottom">{{ $t('suo-shou') }}</div>
                <a class="text-reset d-block fw-600">
                  {{shopInfo.Name}}
                  <span class="ml-2"><i class="fa fa-check-circle" style="color:green"></i></span>
                </a>
                <div class="location opacity-70">{{ shopInfo.Address }}</div>
                <div class="text-center border rounded p-2 mt-3">
                  <div class="rating">
                    <i class="las la-star" v-for="n in 5" :key="n" :class="n <= shopInfo.star ? 'active' : ''"></i>
                  </div>
                  <div class="opacity-60 fs-12">(0{{ $t('ming-ke-hu-ping-lun')}})</div>
                </div>
              </div>
              <div class="row no-gutters align-items-center border-top">
                <div class="col">
                  <a href="javascript:void(0)" @click="toShop" class="d-block btn btn-soft-primary rounded-0">{{ $t('fang-wen-dian-pu-0') }}</a>
                </div>
                <div class="col">
                  <ul class="social list-inline mb-0">
                    <li class="list-inline-item mr-0"  v-if="shopInfo.Facebook" title="facebook">
                      <a  class="facebook" target="_blank" :href="shopInfo.Facebook">
                        <i class="lab la-facebook-f opacity-60"></i>
                      </a>
                    </li>
                    <li class="list-inline-item mr-0"  v-if="shopInfo.Instagram" title="instagram">
                      <a  class="google"  target="_blank" :href="shopInfo.Instagram">
                        <i class="lab la-instagram opacity-60"></i>
                      </a>
                    </li>
                    <li class="list-inline-item mr-0"  v-if="shopInfo.Twitter" title="twitter">
                      <a  class="twitter" target="_blank" :href="shopInfo.Twitter">
                        <i class="lab la-twitter opacity-60"></i>
                      </a>
                    </li>
                    <li class="list-inline-item"  v-if="shopInfo.Youtube" title="youtube">
                      <a  class="youtube" target="_blank" :href="shopInfo.Youtube">
                        <i class="lab la-youtube opacity-60"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="bg-white rounded shadow-sm mb-3" v-if="shopGoodsList.length > 0">
              <div class="p-3 border-bottom fs-16 fw-600">
                {{ $t('chang-xiao-chan-pin') }} </div>
              <div class="p-3">
                <ul class="list-group list-group-flush">
                  <li class="py-3 px-0 list-group-item border-light" v-for="(item, i) in shopGoodsList" :key="i">
                    <div class="row gutters-10 align-items-center">
                      <div class="col-5">
                        <a  class="d-block text-reset">
                          <img class="img-fit lazyload h-xxl-110px h-xl-80px h-120px" :src="item.ImgUrl">
                        </a>
                      </div>
                        <div class="col-7 text-left">
                          <h4 class="fs-13 text-truncate-2">
                            <a  class="d-block text-reset">{{ item.GoodsName }}</a>
                          </h4>
                          <div class="rating rating-sm mt-1">
                            <i class="las la-star" v-for="n in 5" :key="n" :class="n <= item.star ? 'active' : ''"></i>
                          </div>
                          <div class="mt-2">
                            <span class="fs-17 fw-600 text-primary">${{item.Price}}</span>
                          </div>
                        </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-xl-9 order-0 order-xl-1">
            <div class="bg-white mb-3 shadow-sm rounded">
              <div class="nav border-bottom aiz-nav-tabs">
                <a  data-toggle="tab" class="p-3 fs-16 fw-600 text-reset " :class="tab.key == tabIndex ? 'active' : ''" v-for="(tab, i) in tabList" :key="i" @click="changeTab(tab.key)">{{tab.name}}</a>
              </div>

              <div class="tab-content pt-0">
                <div class="tab-pane " :class="tabIndex == 1 ? 'active' : ''" id="tab_default_1" v-show="tabIndex == 1">
                  <div class="p-4">
                    <div class="mw-100 overflow-hidden text-left aiz-editor-data" v-html="detailData.Description">
                    </div>
                  </div>
                </div>

                <!-- <div class="tab-pane " :class="tabIndex == 2 ? 'active' : ''" id="tab_default_2" v-show="tabIndex == 2">
                  <div class="p-4">
                    <div class="embed-responsive embed-responsive-16by9">
                      <iframe class="embed-responsive-item"  src="https://www.test.com/" ></iframe>
                    </div>
                  </div>
                </div>
                <div class="tab-pane " :class="tabIndex == 3 ? 'active' : ''" id="tab_default_3" v-show="tabIndex == 3">
                  <div class="p-4 text-center ">
                    <a href="javascript:void(0)" class="btn btn-primary">下载</a>
                  </div>
                </div> -->
                <div class="tab-pane " :class="tabIndex == 4 ? 'active' : ''" id="tab_default_4" v-show="tabIndex == 4">
                  <div class="p-4">
                    <ul class="list-group list-group-flush">
                      <li class="media list-group-item d-flex" v-for="(item, i) in reviewList" :key="i">
                        <span class="avatar avatar-md mr-3">
                          <img class="lazyload" :src="item.url">
                        </span>
                        <div class="media-body text-left">
                          <div class="d-flex justify-content-between">
                            <h3 class="fs-15 fw-600 mb-0">{{ item.username }}</h3>
                            <span class="rating rating-sm">
                              <i class="las la-star" v-for="n in 5" :key="n" :class="n <= item.star ? 'active' : ''"></i>
                            </span>
                          </div>
                          <div class="opacity-60 mb-2">
                              {{ item.date }}</div>
                          <p class="comment-text">
                              {{ item.message }}
                          </p>
                        </div>
                      </li>
                    </ul>

                    <div class="text-center fs-18 opacity-70" v-if="reviewList.length == 0">
                       {{ $t('shang-wei-dui-ci-chan-pin-jin-hang-ping-lun') }} </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white rounded shadow-sm">
              <div class="border-bottom p-3">
                <h3 class="fs-16 fw-600 mb-0">
                  <span class="mr-4">{{ $t('xiang-guan-chan-pin') }}</span>
                </h3>
              </div>
              <div class="p-3">
                <div class="aiz-carousel gutters-5 half-outside-arrow slick-initialized slick-slider" data-items="5" data-xl-items="3">
                  <button type="button" class="slick-prev slick-arrow" style="" @click="productBannerPrev">
                    <i class="las la-angle-left"></i>
                  </button>
                  <Swipe ref="product"  :touchable="touchable" :width="201" :show-indicators="false" :loop="false"> 
                    <SwipeItem  v-for="(item, i) in goodsList" :key="`banner-${i}`">
                      <goodsListBar :goodsInfo="item" :hideIcon="true"></goodsListBar>
                    </SwipeItem>
                  </Swipe>
                  <button type="button" class="slick-next slick-arrow" style=""   @click="productBannerNext">
                    <i class="las la-angle-right"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="bg-white rounded shadow-sm mt-3 pb-1" v-if="userType != 'seller'">
              <div class="border-bottom p-3">
                <h3 class="fs-18 fw-600 mb-0">
                  <span>{{ $t('Message-to-seller') }}</span>
                </h3>
              </div>
              <p class="fs-14 fw-400 mb-0 ml-3 mt-2" v-if="!isLogin">
                <a href="javascript:void(0)" @click="toPath('login')">{{ $t('deng-lu') }}</a> {{ $t('huo-zhe') }}
                <a class="mr-1" href="avascript:void(0)" @click="toPath('register')">{{ $t('zhu-ce') }}</a>{{ $t('jiang-nin-de-wen-ti-ti-jiao-gei-mai-jia') }}
              </p>

              <div class="query form p-3" v-else>
                <div class="alert alert-danger"  v-show="errorList.length > 0">
                  <ul>
                    <li v-for="(error, i) in errorList" :key="i">{{ error }}</li>
                  </ul>
                </div>
                <form >
                  <input type="hidden" name="product" >
                  <div class="form-group">
                      <textarea class="form-control" ref="myInput" rows="3" cols="40" v-model="form.question"  :placeholder="$t('shu-ru-nin-de-wen-ti')" style="resize: none;"></textarea>
                  </div>
                  <button type="button" class="btn btn-primary" @click="submit">{{ $t('ti-jiao') }}</button>
                </form>
              </div>

              <!-- <div class="question-area my-4  ml-3">
                <div class="border-bottom py-3">
                  <h3 class="fs-18 fw-600 mb-0">
                    <span class="mr-4">其他问题</span>
                  </h3>
                </div>
                <div class="produc-queries border-bottom" v-for="(item, i) in questionList" :key="i">
                  <div class="query d-flex my-4">
                    <span class="mt-1">
                      <img :src="questionUrl" alt="">
                    </span>

                    <div class="ml-3">
                      <div class="fs-14">{{ item.title }}</div>
                      <span class="text-secondary">{{ item.username }} </span>
                    </div>
                  </div>
                  <div class="answer d-flex my-4">
                    <span class="mt-1"> 
                      <img :src="questionUrl2" alt="">
                    </span>

                    <div class="ml-3">
                      <div class="fs-14">
                          {{ item.reply || '卖方尚未回应' }}
                      </div>
                      <span class=" text-secondary"> {{ item.sellerName}} </span>
                    </div>
                  </div>
                </div>
              </div> -->

              <!-- <div class="pagination-area my-4 " v-if="questionList.length > 0">
                 <pageBar :totalPage="56" :page="51"></pageBar>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
            
  </div>
</template>
<script>
import pageBar from '@/components/page'
import goodsListBar from '@/components/goodsList'
import {Swipe, SwipeItem} from 'vant'
import {goodsDetail, getGoodsList, getCartList, addCart, getProductInfo, getProductList, dialogueAdd, getAddressList } from '@/api/index'
import { addOrder } from '@/api/user'
import { resetPrice } from '@/utils/common'
import { getUserType } from '@/utils/auth'
import { productList } from '@/api/seller'
export default {
  components: {
    Swipe,
    SwipeItem,
    goodsListBar,
    pageBar
  },
  data() {
    return {
      categoryUrl: require('../../assets/imgs/icon-category1.jpg'),
      tabIndex: 1,
      productInfo: {},
      form: {
        question: ''
      },
      tabList: [
        {
          name: this.$t('miao-shu'),
          key: 1
        },
        // {
        //   name: '视频',
        //   key: 2
        // },
        // {
        //   name: '下载',
        //   key: 3
        // },
        {
          name: this.$t('ping-lun'),
          key: 4
        },
      ],
      bannerList: [],
      questionUrl: require('../../assets/imgs/question.svg'),
      questionUrl2: require('../../assets/imgs/question2.svg'),
      starUrl: require('../../assets/imgs/star.svg'),
      logoUrl: require('../../assets/imgs/category1.png'),
      ruleUrl: require('../../assets/imgs/rule.jpg'),
      colorList: ['#f00', '#ff0', '#f0f', '#0ff', '#00f', '#0f0'],
      tableList: [
      ],
      optionList: [],
      optionPriceList: [],
      bannerUrl: '',
      bannerIndex: 0,
      indexBak: 0,
      resetIndex: false,
      shopInfo: {},
      goodsList: [],
      shopGoodsList: [],
      questionList: [],
      errorList: [],
      reviewList: [],
      SkuPropId: '',
      dataId: '',
      detailData: {
        CommodityClassification: {},
        count: 1
      },
      shareLink: '',
      optionIds: '',
      addressId: ''
    }
  },
  watch: {
    '$route'(val) {
      let id = this.$route.query.id
      if (this.dataId != id) {
        this.dataId = id
        this.getDetail()
      }
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin
    },
    touchable() {
      return this.$store.state.touchable
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    userType() {
      return getUserType()
    }
  },
  mounted() {
    let id = this.$route.query.id
    this.dataId = id
    this.getDetail()
    this.shareLink = window.location.href

  },
  methods: {
    goToInput(){
      this.$refs.myInput.focus();
    },
    resetPrice,
    getDetail() {
      getProductInfo(this.dataId).then(res => {
        this.detailData = Object.assign({
          count: 1
        }, res.data)
        this.bannerList = JSON.parse(this.detailData.ImgUrls)
        if (this.bannerList.length == 0) {
          this.bannerList = [res.data.ImgUrl]
        }
        this.bannerUrl = this.bannerList[0] 
        // 获取相关产品
        this.getOtherGoods(res.data.CategoryId)
        // 店铺信息
        this.shopInfo = res.data.Shop
        //店铺畅销产品
        if (this.shopInfo.ID) {
          this.getShopProduct(this.shopInfo.ID)
        }
        // 商品规格
        let SaleSpecifications = JSON.parse(res.data.SaleSpecifications)
        if (SaleSpecifications.list && SaleSpecifications.list.length > 0) {
          this.optionList = SaleSpecifications.list.map(v => {
            v.optionIndex = 0
            return v
          })
          this.optionPriceList = JSON.parse(JSON.stringify(SaleSpecifications.price))
          // 获取默认的第一个SkuPropId
          this.SkuPropId = this.optionList[0].skuPropertyId
          console.log("this.SkuPropId",this.SkuPropId)
        }
      })
    },
    getShopProduct(id) {
       let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 10)
      // form.append('Sold', 1)
      form.append('ShopID', id)
      getProductList(form).then(res => {
        this.shopGoodsList = res.data.Items
      })
    },
    getOtherGoods(id) {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 10)
      form.append('categoryId', id)
      getGoodsList(form).then(res => {
        if (res.code == 0) {
          let list = res.data.Items.filter(v => {
            return v.ID != this.dataId
          })
          this.goodsList = list
        }
      })
    },
    bannerPrev() {
      this.resetIndex = false
      let length = this.bannerList.length
      if (this.bannerIndex < 1) {
        return
      }
      if (length - this.bannerIndex < 4) {
        this.bannerIndex -= 1
        this.bannerUrl = this.bannerList[this.bannerIndex]
      } else {
        this.$refs.banner.prev()
      }
    },
    bannerNext() {
      this.resetIndex = false
      let length = this.bannerList.length
      if (this.bannerIndex >= length - 1) {
        return
      }
      if (length - this.bannerIndex > 4) {
        this.$refs.banner.next()
      } else {
        this.bannerIndex += 1
        this.bannerUrl = this.bannerList[this.bannerIndex]
      }
    },
    bannerChange(i) {
      this.bannerIndex = i
      this.bannerUrl = this.bannerList[this.bannerIndex]
      if (i != this.indexBak && this.resetIndex) {
        this.changeBanner(this.indexBak)
      }
    },
    changeBanner(i) {
     
      let length = this.bannerList.length
      if (length - this.bannerIndex > 4) {
         this.$refs.banner.swipeTo(i)
         this.indexBak = i
         this.resetIndex = true
      } else {
        this.bannerIndex = i
        this.bannerUrl = this.bannerList[this.bannerIndex]
      }
    },
    productBannerNext() {
      this.$refs.product.next()
    },
    productBannerPrev() {
      this.$refs.product.prev()
    },
    changeTab(i) {
      this.tabIndex = i
    },
    minusCount() {
      if (this.detailData.count <= 1)  {
        return
      }
      let newCount = this.detailData.count - 1
      this.$set(this.detailData, 'count', newCount)
    },
    plusCount() {
      if (this.detailData.count >= this.detailData.Stock) {
        return
      }
      let newCount = this.detailData.count + 1
      this.$set(this.detailData, 'count', newCount)
    },
    getSum() {
      if (this.detailData.Price) {
        return parseFloat(parseFloat(this.detailData.Price) * this.detailData.count).toFixed(2)
      } else {
        return '0.00'
      }
    },
    addCartList(type, isBuy) {
      if (!this.isLogin) {
        this.$router.push({
          name: 'login'
        })
        return
      }
      if (this.userType == 'seller') {
        this.$toast.fail(this.$t('Merchants-do-not-support-collectible-goods'))
        return
      }
      let form = new FormData()
      form.append('GoodsId', this.dataId)
      form.append('optionIds', this.optionIds)
      form.append('SkuPropId', this.SkuPropId)
      form.append('Attribute', type)
      addCart(form).then(res => {
        if (res.code == 0) {
          if (!isBuy) {
            this.$toast(this.$t('tian-jia-cheng-gong'))
          }
          this.initCart()
         
          if (isBuy) {
            this.$router.push({
              name: 'cart'
            })
            return
          }
          
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    initCart() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 100)
      getCartList(form).then(res => {
				this.$store.state.cartList = res.data.Items.filter(v => {
          return v.Attribute == 1
        })
				this.$store.state.wishList = res.data.Items.filter(v => {
          return v.Attribute == 2
        })
			})
		},
    toBuy() {
      if (!this.isLogin) {
        this.$router.push({
          name: 'login'
        })
        return
      }
      if (this.userType == 'seller') {
        this.$toast.fail(this.$t('The merchant does not support purchasing this item'))
        return
      }

      let form = new FormData()
      form.append('GoodsId', this.dataId)
      form.append('optionIds', this.optionIds)
      form.append('SkuPropId', this.SkuPropId)
      form.append('Attribute', 1)
      addCart(form).then(res => {
        if (res.code == 0) {
          this.initCart()
         
          this.$router.push({
            name: 'cart',
            query: {
              id: this.dataId
            }
          })
          
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    toCategoryDetail(data) {
      this.$router.push({
        name: 'categoryDetail',
        query: {
          name: data.Name,
          id: data.ID
        }
      })
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    submit() {
      // 给卖家留言
      // this.form.question = ''
      if (!this.form.question) {
        this.errorList = []
        this.errorList.push(this.$t('Please-enter-consultation'))
        return
      }
      this.errorList = []
      var f = new FormData()
      f.append('Content', this.form.question)
      f.append('ProductID', this.$route.query.id)
      dialogueAdd(f).then(res => {
        if (res.code === 0) {
          this.form.question = ''
          this.$toast(this.$t('ti-jiao-cheng-gong'))
        } else {
          this.$toast(res.msg)
        }
      })
    },
    toKefu() {
      if (this.isLogin) {
        this.$store.state.showKefuModal = true
        // this.$store.state.kefuUID = '526006'
        this.$store.state.kefuUID = this.detailData.UId
      } else {
        this.$toast.fail(this.$t('qing-xian-deng-lu'))
        this.toPath('login')
      }
    },
    toShop() {
      this.$router.push({
        name: 'shopInfo',
        query: {
          id: this.shopInfo.ID
        }
      })
    },
    getGoodsPrice(isTotal) {
      let ids = this.optionList.map(v => {
        return v.skuPropertyValues[v.optionIndex].propertyValueId
      }).join()
      let price = this.optionPriceList.filter(v => {
        return v.skuPropIds == ids
      })
      this.optionIds = ids
      this.SkuPropId = ids
      let count = 1
      if (isTotal) {
        count = this.detailData.count
      }
      if (price.length > 0) {
        return resetPrice(parseFloat(price[0].skuVal.skuAmount.value) * count)
      } else {
        return resetPrice(parseFloat(this.detailData.Price) * count)
      }
    },
    changeSize(i, index) {
      this.$set(this.optionList[i], 'optionIndex', index)
    }
  }
}
</script>